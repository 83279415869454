// Styles
import '@@/global/scss/index.scss';
import '@@/global/css/global.css';

// Global JS
import Global from '@@/global/js/global.mjs';
import Landing from '@@/pages/landing/landing.mjs';

/**
 * The Hidden Treasures of Object Composition *** Delegation ***
 * https://medium.com/javascript-scene/the-hidden-treasures-of-object-composition-60cd89480381
 *
 * Delegation is when an object forwards or delegates to another object.
 * Used here to imitate class inheritance in JavaScript (instead of leveraging ES6 classes)
 * Code sample: `const delegate = (a, b) => Object.assign(Object.create(a), b);`
 *
 */
const delegate = (a, b) => Object.assign(Object.create(a), b);

(async () => {
    const ReportCheckLanding = delegate(Global, Landing);
    await ReportCheckLanding.init();
    console.debug('Landing init completed');
    /**
     * Load the "external" GIA global (and quite large) dependencies after all report check stuff...
     */
    ReportCheckLanding.loadGlobals();
    /**
     * Init the AWS Real User Monitoring (RUM)
     */
    if (ReportCheckLanding.getAWSEnv() === 'prod' || ReportCheckLanding.getAWSEnv() === 'uat') {
        ReportCheckLanding.initRUM();
    }
})();
