import { h, render } from 'preact';
import LookupForm from '@@components/LookupForm/LookupForm.tsx';
import {
    parseLocale,
    parseLocalStorage,
    parseReportNumber,
    queryParamsToLocalStorage,
    updateUserWayWidgetLanguage,
} from '@@utils/utils.ts';

/**
 * The Landing Singleton Object
 *
 * @class
 * @classdesc Leveraging Singleton pattern to provide a way to group all Landing-specific code into a logical unit and accessed through a single variable.
 * @constructor
 */
const Landing = (function () {
    // Private members
    // Public members
    return {
        /**
         * @property {string} - The locale path to use in cases when locale is not 'en_US'
         */
        localePath: '',
        /**
         * Initialize Report Check V3 (Landing Page)
         *
         * Refer to the comments associated with the nested method calls for more details
         *
         * NOTE: Some methods leveraged are members of the 'Global' Singleton so they can be shared/re-used with Results
         * @method
         * @memberof Landing
         * @returns {Promise<void>}
         */
        init: function () {
            return new Promise(async (resolve, reject) => {
                /** @type {string} */
                const { search, href } = window.location;

                /** @type {string} */
                const localeQueryParam = parseLocale(search);

                /** @type {string} */
                const reportNumberQueryParam = parseReportNumber(search);

                /** @type {string} */
                const { locale: localeLocalStorage = '' } = parseLocalStorage(localStorage || window.localStorage);

                this.setReportNo(reportNumberQueryParam);

                const reportno = this.getReportNo();

                // Set the locale to the value passed as query string if passed
                if (localeQueryParam !== '') {
                    this.setLocale(localeQueryParam);
                    // Update local storage with current query param values
                    queryParamsToLocalStorage(search);
                } else if (localeLocalStorage !== '') {
                    // Otherwise defer to the locale if it has been stored in local storage
                    if (localeLocalStorage) this.setLocale(localeLocalStorage);

                    // Store current query string params as a variable
                    const currentUrlParams = new URLSearchParams(search);

                    // Update the 'locale' query param to match the value in local storage
                    currentUrlParams.set('locale', this.getLocale());

                    // Get the current URL without the query string
                    const baseUrl = href.split('?')[0];

                    // Update the URL with the new query parameters
                    const newUrl = baseUrl + '?' + currentUrlParams.toString();

                    // Update the URL in the browser address bar
                    window.history.replaceState({}, document.title, newUrl);
                }

                // Set the html lang attribute via the current locale
                this.setHTMLLang(this.getLocale());

                // Update the UserWay Widget language with the current locale
                updateUserWayWidgetLanguage(this.getLocale());

                // Set the AWS 'environment' variable to fetch content corresponding rdwb endpoint
                this.setAWSEnv();

                // Set the base URL to source content from corresponding WCS environment
                this.setBaseURL();

                // Even though everything can be fetched and rendered asynchronously, above-the-fold
                // content is prioritized. Elements below-the-fold don't start fetching until after
                // the high-priority elements are complete.
                this.loadHeader()
                    .then(() => {
                        this.loadNotifications(this.getLocale());
                        this.attachCloseNotifClickHandler();
                        this.loadLandingContent().then(() => {
                            this.loadLookupForm();
                        });
                        this.loadRelated();
                        this.loadFooter();
                        this.socialIconSwapFnsToWindow();
                    })
                    .finally(() => {
                        // Remove any and all query string params (reportno, nocache...)
                        // Note that the reportno param already "injected" into lookup input earlier
                        history.pushState(null, '', location.href.split('?')[0]);
                        // For now this only applies 'ready' classname to header (potential for more?)
                        this.resetLoaderStyles();

                        resolve();
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        },

        /**
         * Load the landing page content fetched via WebCenter Sites endpoint
         * @method
         * @memberof Landing
         * @returns {void}
         */
        loadLandingContent: async function () {
            const url = `${this.getBaseURL()}${
                this.getBaseURL().includes('wcs') ? '/sites/gia/' : ''
            }${this.getLocalePath(this.getLocale())}report-check-landing-v2?headless=true&token=false`;
            console.log('url: ', url);

            await this.loadAndRenderHTML(url, '#main-container', {
                importance: 'high',
            });
            document.getElementById('main-container').classList.add('ready');
        },

        /**
         * Load/inject the Lookup Form component into the DOM (via Preact render method)
         * The method is dependent upon the presence of the 'xreport-lookup-form' and thereby the completion of the
         * loadLandingContent method which provides this placeholder div in the DOM
         * @method
         * @memberof Landing
         * @returns {void}
         */
        loadLookupForm: function () {
            // Render the lookup form component and pass the current report number as a prop
            render(
                <LookupForm reportNo={this.getReportNo()} locale={this.getLocale()} />,
                document.getElementById('xreport-lookup-form')
            );
        },
        /**
         * Resets the initial loader styles by adding the 'ready' class to the global header
         * @method
         * @memberof Landing
         * @returns {void}
         */
        resetLoaderStyles: function () {
            document.querySelector('.global-header').classList.add('ready');
        },
    };
})();

export default Landing;
